.user-detail{
    min-width: 320px;
	max-width: 670px;
    margin-top: 1em;
    border-radius: 20px;
    background: #ffffff;
    box-shadow: 10px 10px 40px #ddb2e4, -10px -10px 40px #ffffff;
    font-size: xx-large;
    padding: 15px;
}
.main-card{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.main-card svg{
    height: 5vh;
    width: 5vh;
    cursor: pointer;
    transform: rotate(90deg);
}
.rotated{
    transform: rotate(-180deg) !important;
}