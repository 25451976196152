.settings-card{
    min-width: 320px;
	max-width: 700px;
    margin: 1em auto;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: #FFF;
    font-size: xx-large;
    padding: 15px;
}
.settings-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.settings-option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .2rem 1rem;
    font-size: x-large;
}