body{
	transition: all 1s;
}
.list{
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
	/* text-align: left; */
	margin: 1em auto;
    /* justify-content: space-around; */
}
.topic{
    min-width: 320px;
	max-width: 700px;
	margin: 2em auto;
}
.heading{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 25px;
	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 40px #ddb2e4, -10px -10px 40px #ffffff;
	cursor: pointer;
	transition-duration: 0.3s;
}
.heading:hover{
	background: #ffffff;
	box-shadow: none;
}
.heading-h1{
	margin: 0;
	padding: 10px;
	font-weight: 700;
	font-size: x-large;
	color: #44003ead;
}
.table-wrapper{
	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 40px #ddb2e4, -10px -10px 40px #ffffff;

}
.table-items{
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1.13em;
	width: 100%;
	border-radius: 20px;
	overflow: hidden;
}
.table-items thead tr {
    background-color: #845780f0;
    color: #ffffff;
    text-align: left;
}
.table-items th,
.table-items td {
    padding: 12px 15px;
}

.table-items tbody tr:nth-of-type(even) {
    background-color: #fffffff0;
}
.table-items tbody tr:nth-of-type(odd) {
    background-color: #fdf7ff;
}
.table-items tbody tr:last-of-type {
	border-bottom: none;
}





input[type=checkbox] {
	/* position: absolute; */
	visibility: hidden;
}

input#toggle[type=checkbox]{
	 display:none;
}


label {
	position: absolute;
	height: 40px;
	width: 120px;
	display: block; 
	top: 0px; bottom: 0; right: 0; left:0;
	z-index: 9999;
	cursor: pointer;
	margin: 0 auto;
}

.switch {
 display: block; 
 position: relative;
 border-radius: 25px;
 background: #f1e7f5;
 box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
				 -4px -4px 6px 0 rgba(116, 125, 136, .2), 
	inset -4px -4px 6px 0 rgba(255,255,255,.2),
	inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
 height: 26px;
 width: 60px;
 margin-left: 15px;
}

.switch .button{
 display: block; 
 position: absolute;
 border-radius: 100%;
 background: #eff2f5;
 box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
  7px 7px 20px 0px rgba(0,0,0,.1),
  4px 4px 5px 0px rgba(0,0,0,.1);
 height: 18px; 
 width: 18px;
 top: 3.5px;
 left: 3.5px;
}



/* TOGGLE */

.switch .button {
	transition: left .25s ease-in-out;
	-webkit-transition: left .25s ease-in-out;
}

input[type=checkbox]:checked ~ .switch .button {
	position: absolute;
	left: 38px;
}

input[type=checkbox]:checked ~ .switch {
	background: linear-gradient(0deg, rgb(33, 251, 160) 0%, rgb(76, 234, 160) 100%);

}


/* Spinner */
.spinner {
	width: 20px;
	height: 20px;
	position: absolute;
}
  
  .double-bounce1, .double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #be83e0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
	0%, 100% { -webkit-transform: scale(0.0) }
	50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
	0%, 100% { 
	  transform: scale(0.0);
	  -webkit-transform: scale(0.0);
	} 50% { 
	  transform: scale(1.0);
	  -webkit-transform: scale(1.0);
	}
  }

@media only screen and (max-width: 600px){
	.heading-h1{
		font-size: large;
	}
}