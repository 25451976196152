.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4.5vh 4vw 4.5vh 4vw;
}
.nav-items{
    display: flex;
    align-items: center;
}
.nav-button{
    box-shadow:inset 0px 1px 0px 0px #ffffff;
	background-color:#f1f1f1;
	border-radius:5px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	font-size:15px;
	font-weight:bold;
	padding:10px 25px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
    position: absolute;
    z-index: 100;
}
.nav-donate {
    background-color: #f1d2f5;
    padding: 3px 8px;
    border-radius: 20px;
    font-size: medium;
}
.nav-button:hover {
	background:linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
	background-color:#e9e9e9;
}
.nav-button:active {
	position:relative;
	top:1px;
}    
.dropdown{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dropdown-items{
    position: absolute;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 70px;
    right: 0px;
    min-width: 110px;
    text-align: right;
    border-radius: 10px;
}
.dropdown:hover{
    cursor: pointer;
}
.dropdown-item{
    margin: 10px;
    padding: 10px;
}
.nav-link{
    text-decoration: none;
    font-weight: 500;
    margin: 0 40px 0 40px;
    color: rgb(68, 0, 62);
    transition: all .3s;
}
.nav-link:hover{
    color: #ff2be3;
}

.nav-list{
    display: flex;
    text-decoration: none;
    list-style: none;
    align-items: center;
    min-width: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: x-large;
    font-weight: 300;
}

.menu-icon{
    display: none;
    font-size: 1.8rem;
    padding-top: 15px;
}

.avatar{
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin: 10px;
}

.hidden{
    display: none;
}

@media screen and (max-width: 550px ){
    .mobile{
        display: none;
    }
    .dropdown{
        margin-right: 0;
    }

    .menu-icon{
        display: block;
        color: rgb(30, 0, 58);
        padding: 10px;
        /* position: absolute;
        z-index: 110; */
    }
    .nav-list{
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(120deg,#bb73ff 0%,#f358de 100%);
        position: absolute;
        top: 16vh;
        width: 100%;
        left: -120%;
        transition: all 0.3s ease-out;
        z-index: 10;
    }
    .nav-list.active{
        left: 0;
        transition: all 0.3s ease-out;
        font-size: 1.3rem;
        padding: 0.6rem;
    }
    .nav-link-mobile{
        display: inline-block;
        text-decoration: none;
        font-weight: bolder;
        margin: 10px;
        color: black;
        transition: all .3s;
    }
    .nav-link-mobile:hover{
        color: #f358de;
    }
    .nav-link:hover{
        color: #ffffff;
    }

}

