.lists-card{
  min-width: 320px;
  max-width: 700px;
  margin: 1em auto;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 10px 10px 40px #ddb2e4, -10px -10px 40px #ffffff;
  background-color: #FFF;
  font-size: xx-large;
  padding: 35px;
  transition-duration: 0.3s;
}
.lists-card:hover{
  background: #ffffff;
  box-shadow: none;
}

.lists-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: x-large;
  font-weight: bold;
}
.lists-link{
  color: #44003ecc;
  text-decoration: none;
}