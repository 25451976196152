.about-wrapper{
    max-width: 800px;
    margin: 0px auto;
    padding: 1em 2em;
}
.what{
    color: #F3A0A0;
}
.why{
    color: #BA95D8;
}
.future{
    color: #adbb1f;
}
.emoji{
    font-size: xx-large;
}
.about-wrapper span{
    font-size: 1.3em;
    line-height: 1.618;
}
.about-wrapper h1{
    margin-top: 0;
    padding-top: 1em;
}
.meet{
    text-align: center;
    color: #85DCBA;
}
.people{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.individual{
    text-align: center;
    max-width: 250px;
}
.individual h1{
    margin: 0;
    padding: 0;
}
.individual-img{
    height: 150px;
    width: 150px;
    border-radius: 200px;
    background-size: cover;
    background-position: center;
    margin: 0px auto;
}
.individual-social{
    font-size: xx-large;
}
.individual-social a{
    margin: 5px;
}
.individual-social-linkedin:hover{
    color: #0a66c2 !important;
    transition-duration: 0.3s;
}
.individual-social-github:hover{
    color: #24292e !important;
    transition-duration: 0.3s;
}
