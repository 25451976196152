@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,700;1,800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    background-color: #fdf0ff;
}

.main{
    min-height: 95vh;
}
.nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4.5vh 4vw 4.5vh 4vw;
}
.nav-items{
    display: flex;
    align-items: center;
}
.nav-button{
    box-shadow:inset 0px 1px 0px 0px #ffffff;
	background-color:#f1f1f1;
	border-radius:5px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	font-size:15px;
	font-weight:bold;
	padding:10px 25px;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
    position: absolute;
    z-index: 100;
}
.nav-donate {
    background-color: #f1d2f5;
    padding: 3px 8px;
    border-radius: 20px;
    font-size: medium;
}
.nav-button:hover {
	background:linear-gradient(to bottom, #e9e9e9 5%, #f9f9f9 100%);
	background-color:#e9e9e9;
}
.nav-button:active {
	position:relative;
	top:1px;
}    
.dropdown{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dropdown-items{
    position: absolute;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 70px;
    right: 0px;
    min-width: 110px;
    text-align: right;
    border-radius: 10px;
}
.dropdown:hover{
    cursor: pointer;
}
.dropdown-item{
    margin: 10px;
    padding: 10px;
}
.nav-link{
    text-decoration: none;
    font-weight: 500;
    margin: 0 40px 0 40px;
    color: rgb(68, 0, 62);
    transition: all .3s;
}
.nav-link:hover{
    color: #ff2be3;
}

.nav-list{
    display: flex;
    text-decoration: none;
    list-style: none;
    align-items: center;
    min-width: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: x-large;
    font-weight: 300;
}

.menu-icon{
    display: none;
    font-size: 1.8rem;
    padding-top: 15px;
}

.avatar{
    background-size: cover;
    background-position: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin: 10px;
}

.hidden{
    display: none;
}

@media screen and (max-width: 550px ){
    .mobile{
        display: none;
    }
    .dropdown{
        margin-right: 0;
    }

    .menu-icon{
        display: block;
        color: rgb(30, 0, 58);
        padding: 10px;
        /* position: absolute;
        z-index: 110; */
    }
    .nav-list{
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        background-image: linear-gradient(120deg,#bb73ff 0%,#f358de 100%);
        position: absolute;
        top: 16vh;
        width: 100%;
        left: -120%;
        transition: all 0.3s ease-out;
        z-index: 10;
    }
    .nav-list.active{
        left: 0;
        transition: all 0.3s ease-out;
        font-size: 1.3rem;
        padding: 0.6rem;
    }
    .nav-link-mobile{
        display: inline-block;
        text-decoration: none;
        font-weight: bolder;
        margin: 10px;
        color: black;
        transition: all .3s;
    }
    .nav-link-mobile:hover{
        color: #f358de;
    }
    .nav-link:hover{
        color: #ffffff;
    }

}


body{
	transition: all 1s;
}
.list{
    /* display: flex; */
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
	/* text-align: left; */
	margin: 1em auto;
    /* justify-content: space-around; */
}
.topic{
    min-width: 320px;
	max-width: 700px;
	margin: 2em auto;
}
.heading{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 25px;
	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 40px #ddb2e4, -10px -10px 40px #ffffff;
	cursor: pointer;
	transition-duration: 0.3s;
}
.heading:hover{
	background: #ffffff;
	box-shadow: none;
}
.heading-h1{
	margin: 0;
	padding: 10px;
	font-weight: 700;
	font-size: x-large;
	color: #44003ead;
}
.table-wrapper{
	border-radius: 20px;
	background: #ffffff;
	box-shadow: 10px 10px 40px #ddb2e4, -10px -10px 40px #ffffff;

}
.table-items{
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1.13em;
	width: 100%;
	border-radius: 20px;
	overflow: hidden;
}
.table-items thead tr {
    background-color: #845780f0;
    color: #ffffff;
    text-align: left;
}
.table-items th,
.table-items td {
    padding: 12px 15px;
}

.table-items tbody tr:nth-of-type(even) {
    background-color: #fffffff0;
}
.table-items tbody tr:nth-of-type(odd) {
    background-color: #fdf7ff;
}
.table-items tbody tr:last-of-type {
	border-bottom: none;
}





input[type=checkbox] {
	/* position: absolute; */
	visibility: hidden;
}

input#toggle[type=checkbox]{
	 display:none;
}


label {
	position: absolute;
	height: 40px;
	width: 120px;
	display: block; 
	top: 0px; bottom: 0; right: 0; left:0;
	z-index: 9999;
	cursor: pointer;
	margin: 0 auto;
}

.switch {
 display: block; 
 position: relative;
 border-radius: 25px;
 background: #f1e7f5;
 box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
				 -4px -4px 6px 0 rgba(116, 125, 136, .2), 
	inset -4px -4px 6px 0 rgba(255,255,255,.2),
	inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
 height: 26px;
 width: 60px;
 margin-left: 15px;
}

.switch .button{
 display: block; 
 position: absolute;
 border-radius: 100%;
 background: #eff2f5;
 box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
  7px 7px 20px 0px rgba(0,0,0,.1),
  4px 4px 5px 0px rgba(0,0,0,.1);
 height: 18px; 
 width: 18px;
 top: 3.5px;
 left: 3.5px;
}



/* TOGGLE */

.switch .button {
	transition: left .25s ease-in-out;
	-webkit-transition: left .25s ease-in-out;
}

input[type=checkbox]:checked ~ .switch .button {
	position: absolute;
	left: 38px;
}

input[type=checkbox]:checked ~ .switch {
	background: linear-gradient(0deg, rgb(33, 251, 160) 0%, rgb(76, 234, 160) 100%);

}


/* Spinner */
.spinner {
	width: 20px;
	height: 20px;
	position: absolute;
}
  
  .double-bounce1, .double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #be83e0;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
  }
  
  .double-bounce2 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
  }
  
  @-webkit-keyframes sk-bounce {
	0%, 100% { -webkit-transform: scale(0.0) }
	50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
	0%, 100% { 
	  transform: scale(0.0);
	  -webkit-transform: scale(0.0);
	} 50% { 
	  transform: scale(1.0);
	  -webkit-transform: scale(1.0);
	}
  }

@media only screen and (max-width: 600px){
	.heading-h1{
		font-size: large;
	}
}
.settings-card{
    min-width: 320px;
	max-width: 700px;
    margin: 1em auto;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-color: #FFF;
    font-size: xx-large;
    padding: 15px;
}
.settings-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.settings-option{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .2rem 1rem;
    font-size: x-large;
}
.user-detail{
    min-width: 320px;
	max-width: 670px;
    margin-top: 1em;
    border-radius: 20px;
    background: #ffffff;
    box-shadow: 10px 10px 40px #ddb2e4, -10px -10px 40px #ffffff;
    font-size: xx-large;
    padding: 15px;
}
.main-card{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.main-card svg{
    height: 5vh;
    width: 5vh;
    cursor: pointer;
    transform: rotate(90deg);
}
.rotated{
    transform: rotate(-180deg) !important;
}
.signin{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.signin-about{
    max-width: 80vw;
    padding: 2em;
    font-size: 2.6rem;
    align-items: center;
    text-align: center;
    font-weight: 700;
    line-height: 130%;
    opacity: .73;
    color: rgb(30, 0, 58);
}
.signin-box{
    max-width: 80vw;
    padding: 2em;
    padding-top: 0;
    font-size: large;
}
.login-with-google{
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 1rem 1.5rem 1rem 1.5rem;
    background-color: white;
    border-radius: 14px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    transition-duration: .2s;
    font-weight: 700;
    color: rgb(61, 61, 61);
    margin-bottom: 10vh;
}
.login-with-google:hover{
    box-shadow: 0 6px 8px 0 rgba(0,0,0,.25);
}
.logo-google{
    margin-right: 18px;
    height: 20px;
    width: auto;
}

.logo-main{
    max-width: 33rem;
    margin-top: 10vh;
}

@media screen and (max-width: 550px ){
    .signin-about{
        font-size: 1.8rem;
    }

    .logo-main{
        max-width: 25rem;
    }

}
@charset "UTF-8";
@font-face {
  font-family: 'Notification';
  src: url(/static/media/notification.c0d3c94c.eot);
  src: url(/static/media/notification.c0d3c94c.eot?#iefixs3g3t9) format("embedded-opentype"), url(/static/media/notification.651771e1.woff) format("woff"), url(/static/media/notification.0b4ac1dc.ttf) format("truetype"), url(/static/media/notification.5bee74ca.svg#notification) format("svg");
  font-weight: normal;
  font-style: normal;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-bottom: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover, .notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification';
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: "";
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: "";
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: "";
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: "";
}

.about-wrapper{
    max-width: 800px;
    margin: 0px auto;
    padding: 1em 2em;
}
.what{
    color: #F3A0A0;
}
.why{
    color: #BA95D8;
}
.future{
    color: #adbb1f;
}
.emoji{
    font-size: xx-large;
}
.about-wrapper span{
    font-size: 1.3em;
    line-height: 1.618;
}
.about-wrapper h1{
    margin-top: 0;
    padding-top: 1em;
}
.meet{
    text-align: center;
    color: #85DCBA;
}
.people{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.individual{
    text-align: center;
    max-width: 250px;
}
.individual h1{
    margin: 0;
    padding: 0;
}
.individual-img{
    height: 150px;
    width: 150px;
    border-radius: 200px;
    background-size: cover;
    background-position: center;
    margin: 0px auto;
}
.individual-social{
    font-size: xx-large;
}
.individual-social a{
    margin: 5px;
}
.individual-social-linkedin:hover{
    color: #0a66c2 !important;
    transition-duration: 0.3s;
}
.individual-social-github:hover{
    color: #24292e !important;
    transition-duration: 0.3s;
}

.footer{
    margin-top: 5vh;
}

.footer-hr{
    margin: 0px auto;
    background-color: #219653;
    border: none;
    border-radius: 10px;
    height: 3px;
}
.footer-repo{
    padding: 1em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}
.footer-link{
    color: black;
    margin: 0px 10px;
    font-weight: bolder;
    transition: color .3s;
    text-decoration: none;
}
.footer-link:hover{
    color: #219653;
}
.footer-logo{
    height: 5em;
    width: auto;
    margin: 0px 10px;
}
.footer-logo-collection{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1em;   
}

.footer-div-small{
    background-color: rgb(248, 176, 255);
    color: rgba(0, 0, 0, 0.87);
    padding: 0.2rem 0.5rem 0.3rem 1.3rem;
    border-radius: 100px;
    font-size: small;
    font-weight: 600;
}

.footer-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

@media screen and (max-width: 700px ){
.made-with{
    display:none;
}
}
.lists-card{
  min-width: 320px;
  max-width: 700px;
  margin: 1em auto;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 10px 10px 40px #ddb2e4, -10px -10px 40px #ffffff;
  background-color: #FFF;
  font-size: xx-large;
  padding: 35px;
  transition-duration: 0.3s;
}
.lists-card:hover{
  background: #ffffff;
  box-shadow: none;
}

.lists-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: x-large;
  font-weight: bold;
}
.lists-link{
  color: #44003ecc;
  text-decoration: none;
}
